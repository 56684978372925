<div class="row">
    <div class="col-md-12">
        <h5>
            {{ 'Checkin information' | translate }}
        </h5>
        <p class="text-muted">
            {{ 'COMPANY_FORM_CHECKIN_INFO' | translate }}
        </p>
    </div>
    <div class="col-md-6">
        <div class="form-group">
            <label>{{'Has info modal' | translate}}</label>
            <label class="switch d-block">
                <input type="checkbox" [(ngModel)]="cmsToggle" (ngModelChange)="updateToggleFormControl()">
                <span class="switch-state"></span>
            </label>
        </div>
    </div>
    <div *ngIf="cmsToggle" class="col-md-6">
        <div class="form-group">
            <label>{{'Modal location' | translate}}</label>
            <select class="form-control" [(ngModel)]="selectedLocation" (ngModelChange)="updateLocationFormControl()">
                <option *ngFor="let option of availableLocations" [ngValue]="option.id">
                    {{ option.name | translate }} 
                </option>
            </select>
        </div>
    </div>
    <div *ngIf="cmsToggle" class="col-md-6">
        <div class="form-group">
            <label>{{'Button label' | translate}}</label>
            <input type="text" class="form-control" placeholder="{{'Information' | translate}}" [(ngModel)]="currentTitle" (ngModelChange)="updateTitleFormControl()">
        </div>
    </div>
    <div *ngIf="cmsToggle" class="col-md-6">
        <div class="form-group">
            <label>{{'Content language' | translate}}</label>
            <div class="btn-group btn-group-toggle d-block" ngbRadioGroup name="language" [(ngModel)]="selectedLanguage">
                <label *ngFor="let lang of languages" ngbButtonLabel class="btn-light">
                    <input type="radio" ngbButton [value]="lang" (change)="switchLanguage(lang)">
                    {{ lang.toUpperCase() }}
                </label>
            </div>
        </div>
    </div>
    <div *ngIf="cmsToggle" class="col-md-12">
        <div class="form-group">
            <label>{{'Modal content' | translate}}</label>
            <editor [init]="cmsEditorConfig" [(ngModel)]="currentContent" (ngModelChange)="updateContentFormControl()"></editor>
        </div>
    </div>
</div>